import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  createStyles,
  Collapse,
  Box,
} from '@mantine/core';
import {
  IconChevronRight,
} from '@tabler/icons';
import LinkWrapper from './LinkWrapper';

const useStyles = createStyles((theme, __params, getRef) => {
  const icon = getRef('icon');

  return {
    link: {
      ...theme.fn.focusStyles(),
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
      textDecoration: 'none',
      fontSize: theme.fontSizes.sm,
      color: theme.white,
      padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
      borderRadius: theme.radius.sm,
      fontWeight: 300,

      '&:hover': {
        backgroundColor: theme.fn.lighten(
          theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background,
          0.1,
        ),
      },
    },

    linkIcon: {
      ref: icon,
      color: theme.white,
      opacity: 0.75,
      marginRight: theme.spacing.sm,
    },

    linkActive: {
      '&, &:hover': {
        backgroundColor: theme.fn.lighten(
          theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background,
          0.15,
        ),
        [`& .${icon}`]: {
          opacity: 0.9,
        },
      },
    },

    chevron: {
      transition: 'transform 200ms ease',
    },

    sub: {
      ...theme.fn.focusStyles(),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      textDecoration: 'none',
      fontSize: theme.fontSizes.sm,
      color: theme.white,
      padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
      fontWeight: 300,
      paddingLeft: 23,
      marginLeft: 25,
      borderLeft: `1px solid ${theme.colors.gray[8]}`,

      '&:hover': {
        backgroundColor: theme.fn.lighten(
          theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background,
          0.1,
        ),
      },
    },
  };
});

function LinkGroup({
  icon: Icon,
  label,
  link,
  activeLink,
  setActiveLink,
  ...rest
}) {
  const [opened, setOpened] = useState(false);
  const { classes, cx } = useStyles();
  const hasLinks = Array.isArray(rest.links);
  // const location = useLocation();

  const subMenu = (hasLinks ? rest.links : []).map((sub) => (
    <Link
      className={classes.sub}
      to={sub.link}
      key={sub.label}
      onClick={() => setActiveLink(link)}
    >
      {sub.label}
    </Link>
  ));

  useEffect(() => {
    // setActiveLink(location.pathname.substring(1));
    setOpened((Boolean)(activeLink === link));
  }, [activeLink]);

  return (
    <>
      <LinkWrapper
        className={cx(classes.link, {
          [classes.linkActive]: activeLink === link,
        })}
        hasSubs={hasLinks}
        onClick={() => {
          setOpened((o) => !o);
          setActiveLink(link);
        }}
        to={link}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Icon className={classes.linkIcon} stroke={1} />
          <span>{label}</span>
        </Box>
        {hasLinks && (
          <IconChevronRight
            className={classes.chevron}
            size={14}
            stroke={1.5}
            style={{
              transform: opened ? 'rotate(-90deg)' : 'none',
            }}
          />
        )}
      </LinkWrapper>

      {hasLinks ? <Collapse in={opened}>{subMenu}</Collapse> : null}
    </>
  );
}

LinkGroup.propTypes = {
  icon: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  link: PropTypes.string,
  activeLink: PropTypes.string,
  setActiveLink: PropTypes.func,
};

LinkGroup.defaultProps = {
  activeLink: '',
  setActiveLink: () => {},
  link: '',
};

export default LinkGroup;
