import { Tabs } from '@mantine/core';

function StatusTabs() {
  return (
    <Tabs
      defaultValue="all"
      styles={(theme) => ({
        tabsList: {
          borderBottom: '0px',
        },
        tab: {
          paddingTop: '10px',
          paddingBottom: '30px',
          borderBottom: '1px solid transparent',
          color: theme.colors.dark[1],
          transition: 'color 150ms ease-in-out',

          '&[data-active]': {
            color: theme.colors.dark[7],
          },

          '&:hover': {
            backgroundColor: 'transparent',
            color: theme.colors.dark[7],
            borderBottom: '1px solid transparent',
          },
        },
      })}
    >
      <Tabs.List>
        <Tabs.Tab value="all">All (0)</Tabs.Tab>
        <Tabs.Tab value="draft">Draft ()</Tabs.Tab>
        <Tabs.Tab value="live">Rejected (0)</Tabs.Tab>
        <Tabs.Tab value="live">Approved (0)</Tabs.Tab>
      </Tabs.List>
    </Tabs>
  );
}

export default StatusTabs;
