import request from '@api/wp';

export const getComments = async (questionId) => {
  const response = await request({
    url: `bwqdb/v1/comments?qid=${questionId}`,
    method: 'GET',
  });

  if (response.status === 200) {
    return response.data;
  }

  throw response;
};

export const updateComments = async (data) => {
  const response = await request({
    url: 'bwqdb/v1/comments',
    method: 'POST',
    data,
  });

  if (response.status === 200) {
    return response.data.data;
  }

  throw response;
};
