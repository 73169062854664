/* eslint react/no-array-index-key: 0 */
import { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import {
  UnstyledButton, Text, createStyles, Group, ActionIcon, TextInput, Stack,
} from '@mantine/core';
import { IconTrashX } from '@tabler/icons';
import useIsMounted from '@hooks/useIsMounted';

const useStyles = createStyles((theme) => ({
  button: {
    display: 'flex',
    flex: '1',
    border: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[3]
    }`,
    borderRadius: theme.radius.sm,
    padding: `5px ${theme.spacing.sm}px`,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[0],
    },
  },
  error: {
    borderColor: theme.colors.red[5],
  },
  errorText: {
    fontSize: theme.fontSizes.xs,
    lineHeight: 1.2,
    color: theme.colors.red[7],
  },
}));

function AnswersInput({
  disabled,
  onEnter,
  error,
  answerError,
  defaultValue,
}) {
  const { classes, cx } = useStyles();
  const [value, setValue] = useState('');
  const [options, setOptions] = useState([]);
  const [checked, setChecked] = useState(null);
  const isMounted = useIsMounted();

  const handleOnEnter = (e) => {
    if (e.key === 'Enter' && e.target.value !== '') {
      setOptions((p) => ([e.target.value, ...p]));
      setValue('');
    }
  };

  const removeField = (index) => {
    const data = [...options];
    const prevChecked = data[checked];
    data.splice(index, 1);
    if (checked === index) {
      setChecked(null);
    } else if (data.includes(prevChecked)) {
      const currChecked = data.indexOf(prevChecked);
      setChecked(currChecked);
    }
    setOptions(data);
  };

  useEffect(() => {
    onEnter(options);
  }, [options]);

  useEffect(() => {
    if (isMounted.current) {
      if (defaultValue.length > 0) {
        setOptions(defaultValue);
      }
    }
  }, [defaultValue]);

  return (
    <>
      <TextInput
        disabled={disabled}
        withAsterisk
        mb={10}
        label="Answers"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder="Type and press enter"
        onKeyDown={handleOnEnter}
        error={error}
      />

      <Stack>
        {answerError !== '' ? <span className={classes.errorText}>{answerError}</span> : null}
        {options.map((option, i) => (
          <Group position="apart" key={i}>
            <UnstyledButton
              className={cx(classes.button, { [classes.error]: answerError !== '' })}
            >
              <div>
                <Text weight={500} mb={0}>
                  {option}
                </Text>
              </div>
            </UnstyledButton>

            <ActionIcon variant="filled" color="red" onClick={() => removeField(i)}>
              <IconTrashX size={16} />
            </ActionIcon>
          </Group>
        ))}
      </Stack>
    </>
  );
}

AnswersInput.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onEnter: PropTypes.func.isRequired,
  error: PropTypes.string,
  answerError: PropTypes.string,
  defaultValue: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
};

AnswersInput.defaultProps = {
  error: '',
  answerError: '',
  defaultValue: [],
};

export default AnswersInput;
