import { Outlet } from 'react-router-dom';
import {
  Group, Text, Box, Anchor, Container,
} from '@mantine/core';
import Header from '@components/Header';
import StatusTabs from '@features/StatusTabs';
import CategoryButton from '@features/CategoryButton';
import { gameTypesDataRaw } from '@hooks/useTriviaStackData';
import BulkUploadModal from '@features/BulkUploadModal';
import AddQuestionModal from '@features/AddQuestionModal';

function TriviaStack() {
  const { isLoading, data } = gameTypesDataRaw();

  return (
    <div style={{ width: '100%' }}>
      <Header>
        <Group position="apart">
          <Group spacing={5}>
            <Text>Hi!</Text>
            <Text color="dark.1" size="sm">You have 3 notifications</Text>
            <Anchor component="button" type="button" color="violet" underline size="sm">
              Check
            </Anchor>
          </Group>

          <Group>
            <BulkUploadModal />

            <AddQuestionModal />
          </Group>
        </Group>

        <Group pt="lg" align="flex-start" spacing={60}>
          <Group>
            <CategoryButton data={data} loading={isLoading} />
          </Group>

          <Box>
            <StatusTabs />
          </Box>
        </Group>
      </Header>

      <Container size="md" p="xl">
        <Outlet />
      </Container>
    </div>
  );
}

export default TriviaStack;
