import * as yup from 'yup';

// const IMAGE_FILE_SIZE = 160 * 1024;
// const IMAGE_FILE_FORMAT = [
//   'image/jpeg',
//   'image/png',
// ];

const schema = yup.object().shape({
  game_type_id: yup.number().nullable().required('Select game type'),
  category_id: yup.number().nullable().when('game_type_id', {
    is: (gameTypeId) => gameTypeId >= 1,
    then: yup.number().nullable().required('Select category'),
  }),
  answer_type_id: yup.number().nullable().required('Select answer type'),
  question: yup.string().required('Enter question.'),
  // options: yup.array().when('answer_type_id', {
  //   is: (answerType) => [1].includes(answerType),
  //   then: yup.array().min(2, 'Must have 2 options'),
  // }).required('Provide options and select answer'),
  // answers: yup.array().when('options', {
  //   is: (option) => option.length > 0,
  //   then: yup.array().min(1, 'Select an answer'),
  // }),
  // media_file: yup.mixed()
  //   .test(
  //     'fileFormat',
  //     'Unsupported format',
  //     (value) => value && IMAGE_FILE_FORMAT.includes(value.type),
  //   )
  //   .test(
  //     'fileSize',
  //     'File too large',
  //     (value) => value && value.size <= IMAGE_FILE_SIZE,
  //   )
  //   .required('File required'),
});

export default schema;
