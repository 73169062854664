import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  createStyles, Navbar, Group, UnstyledButton,
} from '@mantine/core';
import {
  IconBellRinging,
  IconDatabaseImport,
  IconReceipt2,
  IconLogout,
} from '@tabler/icons';
import useStore from '@zustand';
import { ReactComponent as BrainwaveLogo } from '@assets/svg/logo.svg';
import LinkGroup from './LinkGroup';

const useStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor: theme.fn.variant({ variant: 'filled', color: theme.primaryColor })
      .background,
    border: 'transparent',
    padding: `40px ${theme.spacing.md}px`,
  },

  logo: {
    fill: theme.colors.dark[7],
  },

  logoWrap: {
    backgroundColor: '#fff',
    width: '32px',
    height: '32px',
    borderRadius: '6px',
    marginLeft: '10px',
  },

  header: {
    paddingBottom: theme.spacing.md,
    marginBottom: theme.spacing.md * 1.5,
  },

  footer: {
    paddingTop: theme.spacing.md,
    marginTop: theme.spacing.md,
    borderTop: `1px solid ${theme.fn.lighten(
      theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background,
      0.1,
    )}`,
  },
}));

const data = [
  { link: 'dashboard', label: 'Dashboard', icon: IconBellRinging },
  {
    label: 'Trivia Stack',
    icon: IconReceipt2,
    link: 'trivia-stack',
    links: [
      { label: 'Questions', link: 'trivia-stack' },
      { label: 'Categories', link: 'trivia-stack/categories' },
    ],
  },
  { link: 'triv5', label: 'Triv5', icon: IconDatabaseImport },
];
function Sidebar() {
  const location = useLocation();
  const slug = location.pathname.substring(1).split('/')[0];
  const [currentLink, setCurrentLink] = useState(slug);
  const { logout } = useStore();
  const { classes } = useStyles();

  const links = data.map((item) => (
    <LinkGroup
      {...item}
      activeLink={currentLink}
      setActiveLink={setCurrentLink}
      key={item.label}
    />
  ));

  return (
    <Navbar width={{ sm: 200, lg: 260 }} className={classes.navbar}>
      <Navbar.Section>
        <Group className={classes.header} position="apart">
          <div className={classes.logoWrap}><BrainwaveLogo className={classes.logo} /></div>
        </Group>
      </Navbar.Section>

      <Navbar.Section grow>
        {links}
      </Navbar.Section>

      <Navbar.Section className={classes.footer}>
        <UnstyledButton className={classes.link} style={{ width: '100%' }} onClick={() => logout()}>
          <IconLogout className={classes.linkIcon} stroke={1.5} />
          <span>Logout</span>
        </UnstyledButton>

        {/* <LinkGroup
          icon={IconLogout}
          label="Logout"
          activeLink=""
          onClick={() => logout()}
        /> */}
      </Navbar.Section>
    </Navbar>
  );
}

export default Sidebar;
