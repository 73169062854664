export const API_URL = process.env.NODE_ENV === 'development' ? 'https://apanel.brainwavegh.com/wp-json/' : 'https://apanel.brainwavegh.com/wp-json/';

export const REST_URL = process.env.NODE_ENV === 'development'
  ? 'https://test.api.triviastack.brainwavegh.com/admin/'
  : 'https://api.triviastack.brainwavegh.com/admin/';

export const TRIVIA_STACK_GAME_LIST = [
  { name: 'QOD', slug: 'qod' },
  { name: 'Sprint', slug: 'sprint' },
  { name: 'Q-Card', slug: 'q-card' },
  { name: 'Risk It', slug: 'risk-it' },
];
