import { useLoaderData } from 'react-router-dom';

function Categories() {
  const data = useLoaderData();

  // console.log(data);

  return (
    <div>
      show here:
      <pre>{JSON.stringify(data)}</pre>
    </div>
  );
}

export default Categories;
