import { useEffect, useState } from 'react';
import { Pagination } from '@mantine/core';
import { useSearchParams, useParams } from 'react-router-dom';
import slugify from '@utils/slugify';
import QuestionListTable from '@features/QuestionListTable';
import {
  gameTypesDataRaw, deleteQuestionData, questionsData,
} from '@hooks/useTriviaStackData';
import useStore from '@zustand';

function Questions() {
  // const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { game } = useParams();
  const paged = Number(searchParams.get('page')) || 1;
  const [filters, setFilter] = useState({ page: paged });
  const [search, setSearch] = useState(undefined);
  const { mutate: deleteQuestion } = deleteQuestionData();
  const { isLoading, data: questions, isFetching } = questionsData(filters);
  const addFilters = useStore((s) => s.addFilter);

  const { data: gameTypes } = gameTypesDataRaw();

  const handlePagination = (page) => {
    setSearchParams({ page });
    setFilter((prev) => ({ ...prev, page }));
    addFilters({ ...filters, page });
  };

  const handleQuestionDelete = (id) => {
    deleteQuestion(id);
  };

  useEffect(() => {
    const oldFilter = filters;
    if (Object.keys(oldFilter).length > 0) {
      delete oldFilter.search_string;
    }
    if (search) {
      setFilter(search);
      addFilters(search);
    } else if (game && gameTypes) {
      const gameTypeId = gameTypes.filter((g) => slugify(g.name) === game)[0].id;
      setFilter({ ...oldFilter, game_type_id: gameTypeId, page: 1 });
      addFilters({ ...oldFilter, game_type_id: gameTypeId, page: 1 });
    }
  }, [game, gameTypes, search]);

  return (
    <div>
      {isLoading ? (
        <>loading</>
      ) : (
        <>
          <QuestionListTable
            data={questions.data}
            loading={isFetching}
            onSearch={(str) => setSearch(str)}
            filters={filters}
            onDelete={handleQuestionDelete}
          />
          <Pagination
            total={Math.ceil(questions.meta.total / questions.meta.per_page)}
            page={paged}
            siblings={3}
            onChange={(page) => handlePagination(page)}
          />
        </>
      )}
    </div>
  );
}

export default Questions;
