import { Outlet } from 'react-router-dom';
import {
  AppShell, useMantineTheme, Container, ScrollArea,
} from '@mantine/core';
import Sidebar from '@features/Sidebar';

function AppLayout() {
  const theme = useMantineTheme();

  return (
    <AppShell
      styles={{
        main: {
          background: theme.colors.dark[7],
          display: 'flex',
          paddingTop: '10px',
          paddingRight: '10px',
          paddingBottom: '10px',
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      navbar={<Sidebar />}
    >
      <Container
        size="xl"
        p="0"
        style={{
          backgroundColor: theme.colors.dark[0], borderRadius: '16px', flex: '1', display: 'flex',
        }}
      >
        <ScrollArea style={{ height: '100%', width: '100%' }}>
          <Outlet />
        </ScrollArea>
      </Container>
    </AppShell>
  );
}

export default AppLayout;
