import { Box, useMantineTheme } from '@mantine/core';
import PropTypes from 'prop-types';

function Header({ children }) {
  const theme = useMantineTheme();

  return (
    <Box px="xl" pt="xl" style={{ backgroundColor: '#fff', borderTopLeftRadius: theme.radius.lg, borderTopRightRadius: theme.radius.lg }}>{children}</Box>
  );
}

Header.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Header;
