import { useRoutes } from 'react-router-dom';
import TriviaStack from './TriviaStack';
import Questions from './Questions';
import Categories from './Categories';
import { categoriesAllData } from '../../utils/hooks/useTriviaStackData';

export default function index() {
  const routes = useRoutes([
    {
      path: '/',
      element: <TriviaStack />,
      children: [
        {
          index,
          element: <Questions />,
          children: [
            {
              path: ':game',
              element: <Questions />,
            },
            {
              path: ':game/:category',
              element: <Questions />,
            },
          ],
        },
        {
          path: '/categories',
          element: <Categories />,
          id: 'categories',
          loader: async () => categoriesAllData(),
        },
      ],
    },
  ]);

  return routes;
}
