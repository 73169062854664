import { useState } from 'react';
import {
  Modal, Button, Group, createStyles, Select, Switch, FileInput, TextInput, Grid, Text,
} from '@mantine/core';
import {
  IconX, IconCheck, IconUpload,
} from '@tabler/icons';
import * as yup from 'yup';
import { useForm, yupResolver } from '@mantine/form';
import useStore from '@zustand';
import {
  gameTypesData, gameAnswerTypesData, categoriesData, importQuestionsData, gameTypesDataRaw,
} from '@hooks/useTriviaStackData';

const CSV_FILE_SIZE = 160 * 1024;
const CSV_FILE_FORMAT = [
  'text/csv',
];

const schema = yup.object().shape({
  game_type_id: yup.number().nullable().required('Select game type'),
  category_id: yup.number().nullable().when('game_type_id', {
    is: (gameTypeId) => gameTypeId > 1,
    then: yup.number().nullable().required('Select category'),
  }),
  answer_type_id: yup.number().nullable().required('Select answer type'),
  questions_file: yup.mixed()
    .test(
      'fileFormat',
      'Unsupported format',
      (value) => value && CSV_FILE_FORMAT.includes(value.type),
    )
    .test(
      'fileSize',
      'File too large',
      (value) => value && value.size <= CSV_FILE_SIZE,
    )
    .required('File required'),
});

const useStyles = createStyles(() => ({}));

function BulkUploadModal() {
  const currentUser = useStore((s) => s.user);
  const [open, setOpen] = useState(false);
  const [publish, setPublish] = useState(false);
  const [showCategorySelect, setShowCategory] = useState(false);
  const { theme } = useStyles();
  const { mutate: uploadQuestions, status: uploadStatus } = importQuestionsData();
  const { data: gameTypes } = gameTypesData();
  const { data: gameCategories } = categoriesData();
  const { data: gameAnswerTypes } = gameAnswerTypesData();
  const { data: gameTypeMeta } = gameTypesDataRaw();
  const { onSubmit, getInputProps, ...form } = useForm({
    validate: yupResolver(schema),
    initialValues: {
      game_type_id: null,
      category_id: null,
      answer_type_id: null,
      chances: '',
      points: '',
      questions_file: null,
      author: currentUser.email,
    },
  });
  const { onChange: handleOnGameChange, ...gameChangeInputProps } = getInputProps('game_type_id');

  const handleSubmit = async (data) => {
    uploadQuestions(data, {
      onSuccess: () => {
        form.onReset();
      },
      onError: (error) => {
        if (error.response) {
          // form field errors
          if (error.response.status === 422) {
            form.setErrors(
              error.response.data.errors,
            );
          }
        }
      },
    });
  };

  const handleOnClose = () => {
    setOpen(false);
    form.reset();
  };

  return (
    <>
      <Modal
        opened={open}
        onClose={handleOnClose}
        title="Upload questions"
        size={640}
      >
        <form onSubmit={onSubmit(handleSubmit)}>
          <Grid>
            <Grid.Col span={4}>
              <Select
                {...gameChangeInputProps}
                label="Trivia stack game"
                placeholder="select"
                onChange={(value) => {
                  const meta = gameTypeMeta.filter((g) => g.id === value)[0];
                  handleOnGameChange(value);
                  setShowCategory(value !== 1 && value !== '');
                  const values = {
                    chances: meta.chances,
                    points: meta.points,
                  };
                  if (value === 1) {
                    values.category_id = 1;
                  }
                  form.setValues(values);
                }}
                data={gameTypes}
              />
            </Grid.Col>

            <Grid.Col span={4}>
              {showCategorySelect
                ? <Select
                    {...getInputProps('category_id')}
                    label="Category"
                    placeholder="select"
                    data={gameCategories}
                  />
                : null}
            </Grid.Col>
          </Grid>

          <Grid>
            <Grid.Col span={4}>
              <Select
                {...getInputProps('answer_type_id')}
                label="Answer type"
                placeholder="select"
                data={gameAnswerTypes}
              />
            </Grid.Col>

            <Grid.Col span={4}>
              <Select
                {...getInputProps('author')}
                label="Writer"
                placeholder="select"
                data={[
                  { value: 'dev@mobivs.com', label: 'Antwi-Boasiako' },
                ]}
              />
            </Grid.Col>
          </Grid>

          <Grid mb={8}>
            <Grid.Col span={4}>
              <TextInput
                {...getInputProps('chances')}
                label="Chances"
                placeholder="0"
              />
            </Grid.Col>

            <Grid.Col span={4}>
              <TextInput
                {...getInputProps('points')}
                label="Points"
                placeholder="0"
              />
            </Grid.Col>

            <Grid.Col span="content">
              <Group align="flex-start" pt={30}>
                <Switch
                  checked={publish}
                  onChange={(event) => setPublish(event.currentTarget.checked)}
                  color="teal"
                  size="md"
                  label="Publish"
                  thumbIcon={publish
                    ? (
                      <IconCheck
                        size={12}
                        color={theme.colors.teal[theme.fn.primaryShade()]}
                        stroke={3}
                      />
                    ) : (
                      <IconX
                        size={12}
                        color={theme.colors.red[theme.fn.primaryShade()]}
                        stroke={3}
                      />
                    )}
                />
              </Group>
            </Grid.Col>
          </Grid>

          <FileInput
            clearable
            label="Upload"
            placeholder="Select question sheet"
            error={form.errors?.questions_file}
            onChange={(file) => form.setFieldValue('questions_file', file)}
            description={(
              <Text>
                We only accept
                {' '}
                <strong><i>.csv</i></strong>
                ,
                {' '}
                <strong><i>.xlsx</i></strong>
                {' '}
                files that are less than
                {' '}
                <strong>30mb</strong>
                {' '}
                in size.
              </Text>
            )}
            icon={<IconUpload size={14} />}
            accept="text/csv"
          />

          <Group position="right" mt={42}>
            <Button variant="subtle" onClick={() => { form.onReset(); setOpen(false); }}>Cancel</Button>
            <div style={{ width: '200px' }}>
              <Button
                fullWidth
                size="lg"
                radius="xl"
                type="submit"
                loading={uploadStatus === 'loading'}
                disabled={uploadStatus === 'loading'}
              >
                Submit

              </Button>
            </div>
          </Group>
        </form>
      </Modal>

      <Button variant="outline" color="dark.1" radius="xl" onClick={() => setOpen(true)}>
        Upload
      </Button>
    </>
  );
}

export default BulkUploadModal;
