import PropTypes from 'prop-types';
import { useLocation, Navigate, Outlet } from 'react-router-dom';

function PrivateRoute({ children, redirectPath = '/', userCan }) {
  const location = useLocation();

  if (!userCan) {
    return <Navigate to={redirectPath} state={{ from: location }} replace />;
  }
  return children || <Outlet />;
}

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
  redirectPath: PropTypes.string,
  userCan: PropTypes.bool.isRequired,
};

PrivateRoute.defaultProps = {
  redirectPath: '/',
};

export default PrivateRoute;
