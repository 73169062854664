import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  UnstyledButton, ActionIcon, Image, createStyles,
} from '@mantine/core';
import { IconTrash } from '@tabler/icons';

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
  },
  uploadButton: {
    position: 'absolute',
    zIndex: '10',
    bottom: '0px',
    fontSize: theme.fontSizes.sm,
    width: '100%',
    height: '100%',
    textAlign: 'center',
    padding: '10px',
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'center',
  },
  trash: {
    position: 'absolute',
    top: '-30px',
    right: 0,
  },
}));

function ImageInput({
  onChange,
  defaultImage,
  ...rest
}) {
  const inputRef = useRef(null);
  const { classes } = useStyles();
  const [preview, setPreview] = useState(defaultImage || null);

  const handleImageSelected = (event) => {
    setPreview(URL.createObjectURL(event.target.files[0]));
    onChange(event.target.files && event.target.files[0]);
  };

  const removeImage = () => {
    setPreview(null);
    onChange(null);
  };

  return (
    <div className={classes.wrapper}>
      {preview === null ? (
        <UnstyledButton
          onClick={() => inputRef.current.click()}
          className={classes.uploadButton}
        >
          Browse
        </UnstyledButton>
      ) : (
        <ActionIcon
          className={classes.trash}
          variant="filled"
          color="red"
          onClick={removeImage}
        >
          <IconTrash size={16} />

        </ActionIcon>
      )}
      <Image
        width={150}
        height={150}
        src={preview}
        alt="Question image"
        withPlaceholder
      />
      <input
        ref={inputRef}
        style={{ display: 'none' }}
        onChange={handleImageSelected}
        type="file"
        {...rest} // eslint-disable-line
      />
    </div>
  );
}

ImageInput.propTypes = {
  onChange: PropTypes.func,
  defaultImage: PropTypes.string,
};

ImageInput.defaultProps = {
  onChange: () => {},
  defaultImage: '',
};

export default ImageInput;
