import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { UnstyledButton } from '@mantine/core';

function LinkWrapper({
  children, hasSubs, to, ...rest
}) {
  return hasSubs ? (
    <UnstyledButton {...rest}>{children}</UnstyledButton>
  ) : (
    <Link {...rest} to={to}>{children}</Link>
  );
}

LinkWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  hasSubs: PropTypes.bool.isRequired,
  to: PropTypes.string,
};

LinkWrapper.defaultProps = {
  to: '',
};

export default LinkWrapper;
