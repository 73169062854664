import request from '@api/rest';

export const getGameTypes = async () => {
  const response = await request({
    url: 'game-types',
    method: 'GET',
  });

  if (response.status === 200) {
    return response.data.data;
  }

  throw response;
};

export const getCategories = async () => {
  const response = await request({
    url: 'categories',
    method: 'GET',
  });

  if (response.status === 200) {
    return response.data.data;
  }

  throw response;
};

export const getAnswerType = async () => {
  const response = await request({
    url: 'answer-types',
    method: 'GET',
  });

  if (response.status === 200) {
    return response.data.data;
  }

  throw response;
};

export const getQuestions = async (query) => {
  const response = await request({
    url: 'questions',
    method: 'GET',
    params: query,
  });

  if (response.status === 200) {
    return {
      data: response.data.data,
      meta: response.data.meta,
    };
  }

  throw response;
};

export const uploadQuestions = async (data) => {
  const response = await request({
    url: 'questions/import',
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response;
};

export const updateQuestion = async (data) => {
  const response = await request({
    url: `questions/${data.id}`,
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  if (response.status === 200) {
    return response.data.data;
  }

  throw response;
};

export const addQuestion = async (data) => {
  const response = await request({
    url: 'questions',
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  if (response.status === 201) {
    return response.data.data;
  }

  throw response;
};

export const deleteQuestion = async (id) => {
  const response = await request({
    url: `questions/${id}`,
    method: 'DELETE',
  });

  if (response.status === 200) {
    return response;
  }

  throw response;
};
