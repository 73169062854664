import create from 'zustand';
import { persist } from 'zustand/middleware';
import authSlice from './slices/authSlice';
import appSlice from './slices/appSlice';

const useStore = create(
  persist(
    (...a) => ({
      ...authSlice(...a),
      ...appSlice(...a),
    }),
    {
      name: 'bwqdb-ai-storage',
      getStorage: () => localStorage,
      partialize: (state) => Object.fromEntries(
        Object.entries(state).filter(([key]) => !['filters', 'editTriviaStackQuestion'].includes(key)),
      ),
    },
  ),
);

export default useStore;
