import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { getComments, updateComments } from '@services/comment.service';

export const commentsData = (questionId) => useQuery(['comments', questionId], () => getComments(questionId), {
  enabled: !!questionId,
  retry: false,
});

export const updateCommentData = () => {
  const queryClient = useQueryClient();

  return useMutation(updateComments, {
    onSuccess: (data) => {
      const queryKey = ['comments', data.question_id];

      queryClient.setQueryData(queryKey, (oldData) => [
        ...oldData,
        data.comment,
      ]);
    },
  });
};
