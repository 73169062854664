import axios from 'axios';
import { REST_URL } from '@utils/constants';

const client = axios.create({
  baseURL: REST_URL,
  headers: {
    Accept: 'application/json',
    Authorization: `Basic ${window.btoa('1234:1234')}`,
  },
});

const request = ({ ...options }) => {
  const onSuccess = (response) => response;
  const onError = (error) => {
    if (error.request.status === 401) {
      // what is to be do?
    }

    return Promise.reject(error);
  };

  return client(options).then(onSuccess).catch(onError);
};

export default request;
