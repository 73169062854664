/* eslint react/no-array-index-key: 0 */
import { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import {
  UnstyledButton, Checkbox, Text, createStyles, Group, ActionIcon, TextInput, Stack,
} from '@mantine/core';
import { IconTrashX } from '@tabler/icons';
import useIsMounted from '@hooks/useIsMounted';

const useStyles = createStyles((theme) => ({
  button: {
    display: 'flex',
    flex: '1',
    border: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[3]
    }`,
    borderRadius: theme.radius.sm,
    padding: `5px ${theme.spacing.sm}px`,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[0],
    },
  },
  error: {
    borderColor: theme.colors.red[5],
  },
  errorText: {
    fontSize: theme.fontSizes.xs,
    lineHeight: 1.2,
    color: theme.colors.red[7],
  },
}));

function OptionsInput({
  disabled,
  onEnter,
  error,
  answerError,
  onAnswer,
  defaultValues,
}) {
  const { classes, cx } = useStyles();
  const [value, setValue] = useState('');
  const [options, setOptions] = useState([]);
  const [checked, setChecked] = useState(null);
  const isMounted = useIsMounted();

  const handleOnEnter = (e) => {
    if (e.key === 'Enter' && e.target.value !== '') {
      setOptions((p) => ([String(e.target.value), ...p]));
      setValue('');
    }
  };

  const removeField = (index) => {
    const data = [...options];
    const prevChecked = data[checked];
    data.splice(index, 1);
    if (checked === index) {
      setChecked(null);
      onAnswer(null);
    } else if (data.includes(prevChecked)) {
      const currChecked = data.indexOf(prevChecked);
      setChecked(currChecked);
    }
    setOptions(data);
  };

  const checkOption = (i) => {
    onAnswer(options[i]);
    setChecked(i);
  };

  useEffect(() => {
    onEnter(options);
  }, [options]);

  useEffect(() => {
    if (isMounted.current) {
      if (defaultValues?.options && defaultValues?.options.length > 0) {
        const { options: opt, answer } = defaultValues;
        const filteredOtp = opt.filter((n) => n != null);
        setOptions(filteredOtp);
        setChecked(filteredOtp.indexOf(answer));
      }
    }
  }, [defaultValues?.options]);

  return (
    <>
      <TextInput
        disabled={disabled}
        withAsterisk
        mb={10}
        label="Options"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder="Type and press enter"
        onKeyDown={handleOnEnter}
        error={error}
      />

      <Stack>
        {answerError !== '' ? <span className={classes.errorText}>{answerError}</span> : null}
        {options.map((option, i) => (
          <Group position="apart" key={i}>
            <UnstyledButton
              className={cx(classes.button, { [classes.error]: answerError !== '' })}
              onClick={() => checkOption(i)}
            >
              <Checkbox
                checked={checked === i}
                onChange={() => {}}
                tabIndex={-1}
                size="md"
                mr="xl"
                color="green.7"
                styles={{ input: { cursor: 'pointer' } }}
              />

              <div>
                <Text weight={500} mb={0}>
                  {String(option)}
                </Text>
              </div>
            </UnstyledButton>

            <ActionIcon variant="filled" color="red" onClick={() => removeField(i)}>
              <IconTrashX size={16} />
            </ActionIcon>
          </Group>
        ))}
      </Stack>
    </>
  );
}

OptionsInput.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onEnter: PropTypes.func.isRequired,
  error: PropTypes.string,
  answerError: PropTypes.string,
  onAnswer: PropTypes.func.isRequired,
  defaultValues: PropTypes.shape({
    answer: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  }),
};

OptionsInput.defaultProps = {
  error: '',
  answerError: '',
  defaultValues: [],
};

export default OptionsInput;
