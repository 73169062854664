import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDebouncedValue } from '@mantine/hooks';
import {
  TextInput, ActionIcon, Loader, CloseButton,
} from '@mantine/core';
import {
  IconListSearch, IconSearch,
} from '@tabler/icons';

function Search({ onSearch, loading }) {
  const [value, setValue] = useState('');
  const [debounced] = useDebouncedValue(value, 200);

  const onClear = () => setValue('');

  useEffect(() => {
    if (debounced.length > 0) {
      onSearch({ search_string: debounced });
    }
  }, [debounced]);

  useEffect(() => {
    if (!value.length > 0) {
      onSearch(undefined);
    }
  }, [value]);

  return (
    <TextInput
      icon={<IconListSearch size={16} />}
      placeholder="search..."
      onChange={(e) => setValue(e.target.value)}
      radius="lg"
      value={value}
      rightSection={{
        [value && loading]: <Loader size="xs" />,
        [value && !loading]: <CloseButton onClick={onClear} title="Clear search" size="lg" radius="lg" iconSize={16} />,
      }.true || <ActionIcon variant="filled" radius="lg"><IconSearch size={16} /></ActionIcon>}
    />
  );
}

Search.propTypes = {
  onSearch: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

Search.defaultProps = {
  loading: false,
};

export default Search;
