import { RouterProvider, createBrowserRouter } from 'react-router-dom';
// import Auth from '@pages/Auth';
import Dashboard from '@pages/Dashboard';
import TriviaStack from '@pages/TriviaStack';
import AppLayout from '@components/layouts/AppLayout';
import useStore from '@zustand';
import Login from '../../../pages/Auth/Login';
import PrivateRoute from './PrivateRoute';

const router = (isAuth) => createBrowserRouter([
  {
    path: '/',
    children: [
      {
        path: '/',
        element: <PrivateRoute userCan={!!isAuth}><AppLayout /></PrivateRoute>,
        children: [
          {
            index: true,
            path: '/dashboard',
            element: <Dashboard />,
          },
          {
            path: '/trivia-stack/*',
            element: <TriviaStack />,
          },
        ],
      },
      {
        path: '/*',
        children: [
          {
            index: true,
            element: <Login />,
          },
        ],
      },
      {
        path: '*',
        element: <NoMatch />,
      },
    ],
  },
]);

function RenderRoutes() {
  const user = useStore((s) => s.user);
  const isAuth = user && Object.keys(user).length > 0;

  return (
    <RouterProvider router={router(isAuth)} />
    // <Routes>
    //   <Route
    //     path="/*"
    //     element={
    //       <PrivateRoute userCan={!!isAuth}>
    //         <AppLayout />
    //       </PrivateRoute>
    //     }
    //   >
    //     <Route path="dashboard" element={<Dashboard />} />
    //     <Route path="trivia-stack" element={<TriviaStack />}>
    //       <Route path=":game" element={<TriviaStack />} />
    //       <Route path=":game/:category" element={<TriviaStack />} />
    //     </Route>
    //     <Route path="trivia-stack/categories" element={<>Category</>} />
    //     <Route path="triv5" element={<>Hey</>} />
    //   </Route>

  //   <Route index path="/*" element={<Auth />} />
  //   <Route path="*" element={<NoMatch />} />
  // </Routes>
  );
}

export default RenderRoutes;

function NoMatch() {
  return (
    <div>
      <h2>It looks like you&lsquo;re lost...</h2>
      <p>Go to the home page</p>
    </div>
  );
}
