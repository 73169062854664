import { Box } from '@mantine/core';
import PropTypes from 'prop-types';
import useStore from '@zustand';

function Comment({ data }) {
  const currentUser = useStore((s) => s.user);
  const uid = parseInt(data.user_id, 10);
  const cuid = parseInt(currentUser.ID, 10);
  const isMe = uid === cuid;

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: isMe ? theme.colors.gray[2] : theme.colors.dark,
        margin: isMe ? '0 0 0 auto' : '0 auto 0 0',
        width: 'auto',
        borderRadius: '10px 10px 0 10px',
        padding: `12px ${theme.spacing.xl}px`,
        color: isMe ? theme.colors.dark : theme.white,
      })}
    >
      {data.comment_content}
    </Box>
  );
}

Comment.propTypes = {
  data: PropTypes.shape({
    user_id: PropTypes.string,
    comment_ID: PropTypes.string,
    comment_content: PropTypes.string,
  }).isRequired,
};

export default Comment;
