import { client } from './wp';

export const signInWithWP = async (user) => {
  const res = await client.post('api/v1/auth', user).catch((err) => err);

  if (res instanceof Error) {
    throw res;
  }

  return {
    token: res.data.data.jwt,
    user: res.data.data.user,
  };
};

export const registerUser = async () => {};
