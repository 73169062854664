export default function appSlice(set) {
  return {
    filters: {
      page: 1,
    },
    editTriviaStackQuestion: {},
    setEditQuestion: (payload) => set((state) => ({
      ...state,
      editTriviaStackQuestion: payload,
    })),
    addFilter: (filter) => set((state) => ({
      ...state,
      filters: {
        ...state.filters,
        ...filter,
      },
    })),
  };
}
