import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Menu, UnstyledButton, Group, Text,
} from '@mantine/core';
import { IconChevronDown } from '@tabler/icons';

export default function CategoryButton({
  data,
  loading,
}) {
  const { game } = useParams();
  const selector = (item) => data.filter((el) => el.name.replaceAll(' ', '-').toLowerCase() === item)[0];
  const [selected, setSelected] = useState('Filter by game');

  useEffect(() => {
    if (game) {
      setSelected(selector(game)?.name);
    }
  }, [game, data]);

  return (
    <Menu withArrow width={200}>
      <Menu.Target>
        <UnstyledButton>
          {loading ? (<>loading</>) : (
            <Group>
              <Text size="xl">{selected}</Text>
              <IconChevronDown size={16} />
            </Group>
          )}
          <Text color="dark.1" size="xs">selected game</Text>
        </UnstyledButton>
      </Menu.Target>

      <Menu.Dropdown>
        {data && data.map((item) => {
          const slug = item.name.replaceAll(' ', '-').toLowerCase();
          return (
            <Menu.Item
              key={item.id}
              component={Link}
              to={slug}
            >
              {item.name}
            </Menu.Item>
          );
        })}
      </Menu.Dropdown>
    </Menu>
  );
}

CategoryButton.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  })),
  loading: PropTypes.bool,
};

CategoryButton.defaultProps = {
  loading: false,
  data: [],
};
