import axios from 'axios';
import { API_URL } from '../utils/constants';
import useStore from '../stores/zustand';

export const client = axios.create({
  baseURL: API_URL,
});

const request = ({ ...options }) => {
  const { token, logout } = useStore.getState();
  client.defaults.headers.common.Authorization = `Bearer ${token}`;

  const onSuccess = (response) => response;
  const onError = (error) => {
    if (error.request.status === 401) {
      logout();
    }

    return Promise.reject(error);
  };

  return client(options).then(onSuccess).catch(onError);
};

export default request;
