import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Table, Checkbox, Group, Avatar, Text, Stack,
  createStyles, ActionIcon, Image, Box, Button, Menu, LoadingOverlay,
  Select, Badge, Indicator,
} from '@mantine/core';
import {
  IconBrandHipchat, IconDotsVertical, IconExternalLink, IconTrash, IconArrowsLeftRight,
} from '@tabler/icons';
import moment from 'moment';
import Search from '@features/Search';
import { updateQuestionData } from '@hooks/useTriviaStackData';
import CommentDrawer from '@features/CommentDrawer/CommentDrawer';
import useStore from '@zustand';

const useStyles = createStyles((theme, __params, getRef) => ({
  rowSelected: {
    backgroundColor: theme.fn.rgba(theme.colors[theme.primaryColor][1], 0.1),
  },
  rowDeleting: {
    backgroundColor: theme.fn.rgba(theme.colors.red[1], 0.5),
  },
  td: {
    verticalAlign: 'top',
    paddingTop: '25px !important',
    paddingBottom: '5px !important',
    [`&:hover .${getRef('rowActions')}`]: {
      visibility: 'visible',
    },
  },
  wrap: {
    backgroundColor: theme.white,
    borderBottomRightRadius: theme.radius.lg,
    borderBottomLeftRadius: theme.radius.lg,
    borderTopRightRadius: theme.radius.lg,
  },
  rowActions: {
    ref: getRef('rowActions'),
    visibility: 'hidden',
    'button, a': {
      color: theme.colors.dark[1],
      backgroundColor: 'transparent',
    },
    'button:hover, a:hover': {
      color: theme.colors.dark[4],
      backgroundColor: 'transparent',

      '&.reject': {
        color: theme.colors.red[5],
      },
      '&.approve': {
        color: theme.colors.green[7],
      },
      '&.edit': {
        color: theme.colors.yellow[7],
      },
      '&.google': {
        color: theme.colors.blue[5],
      },
    },
  },
  answer: {
    color: theme.colors.green[7],
    fontWeight: 'medium',
  },
}));

function QuestionListTable({
  data,
  loading,
  onSearch,
  filters,
  onDelete, // eslint-disable-line
}) {
  const selectedQuestion = useRef(null);
  const [selection, setSelection] = useState([]);
  const [deletion, setDeletion] = useState([]);
  const [openComment, setOpenComment] = useState(false);
  const { classes, cx } = useStyles();
  const { mutate: updateQuestion } = updateQuestionData(filters);
  const editQuestion = useStore((s) => s.setEditQuestion);

  const toggleAll = () => setSelection(
    (current) => (current.length === data.length ? [] : data.map((item) => item.id)),
  );

  const toggleRow = (id) => setSelection(
    (current) => (current.includes(id) ? current.filter((item) => item !== id) : [...current, id]),
  );

  const handleApproval = (action, question) => {
    const isLive = action === 'approve' ? 1 : 2;
    const newQuestion = question;
    delete newQuestion.active_status;
    delete newQuestion.is_live;

    updateQuestion({ ...newQuestion, approval_state: isLive });
  };

  const handleCommentAdded = (newQuestionData) => {
    updateQuestion(newQuestionData);
  };

  const handleDeleting = (id) => { // eslint-disable-line
    setDeletion(
      (current) => (current.includes(id)
        ? current.filter((item) => item !== id)
        : [...current, id]),
    );
    onDelete(id);
  };

  const rows = data.map((item, idx) => {
    const key = `${item.id}-${idx}`;
    const selected = selection.includes(item.id);
    const deleting = deletion.includes(item.id);
    const { media } = item;
    const commentStats = JSON.parse(item.wordpress_data || '{}');
    const filteredOptions = item.options.filter((n) => n != null);

    return (
      <tr
        key={key}
        className={cx({
          [classes.rowSelected]: selected,
          [classes.rowDeleting]: deleting,
        })}
      >
        <td className={classes.td}>
          <div style={{ paddingTop: '3px', textAlign: 'center' }}>
            <Checkbox
              checked={selection.includes(item.id)}
              onChange={() => toggleRow(item.id)}
              transitionDuration={0}
            />
          </div>
        </td>
        <td className={classes.td}>
          <Group spacing={5} mb="md">
            <Avatar size={26} src={item.avatar} radius={26} />
            <Text color="dark.1">{item.author}</Text>
            <Text size="lg">&middot;</Text>
            <Text color="dark.1">{moment(item.created_at).fromNow()}</Text>

            {commentStats?.comment_count && (
              <>
                <Text size="lg">&middot;</Text>
                <Text color="dark.1">
                  {commentStats?.comment_count}
                  {' '}
                  comments
                </Text>
              </>
            )}
          </Group>

          <Stack spacing={0}>
            <Text size="xl" weight={500}>
              {item.question}
            </Text>

            <Group spacing={25}>
              {{
                1: filteredOptions.map((opt, optIdx) => {
                  const optKey = `${opt}-${optIdx}`;
                  return (
                    <Text
                      key={optKey}
                      className={cx({ [classes.answer]: opt === item.answers[0] })}
                    >
                      {String(opt)}
                    </Text>
                  );
                }),
                2: item.answers.map((opt, asnIdx) => {
                  const ansKey = `${opt.id}-${asnIdx}`;
                  return (
                    <Text
                      key={ansKey}
                      className={cx({ [classes.answer]: true })}
                    >
                      {String(opt)}
                    </Text>
                  );
                }),
              }[item.answer_type_id]}
            </Group>
          </Stack>

          <Group mt="md" className={classes.rowActions} style={{ marginLeft: '-8px' }}>
            <Button
              compact
              variant="subtle"
              className="edit"
              onClick={() => editQuestion(item)}
            >
              Edit
            </Button>

            {parseInt(item.approval_state, 10) === 1
              ? (
                <Button
                  compact
                  variant="subtle"
                  className="reject"
                  onClick={() => handleApproval('reject', item)}
                >
                  Reject
                </Button>
              )
              : (
                <Button
                  compact
                  variant="subtle"
                  className="approve"
                  onClick={() => handleApproval('approve', item)}
                >
                  Approve
                </Button>
              )}

            <Button
              compact
              component="a"
              href={`http://www.google.com/search?q=${item.question}`}
              target="_blank"
              variant="subtle"
              className="google"
              leftIcon={<IconExternalLink size={14} />}
            >
              Google it
            </Button>
          </Group>
        </td>
        <td className={classes.td}>
          <Stack align="end">
            <Group spacing={0}>
              <Group mr={10}>
                {{
                  1: <Badge color="blue">Approved</Badge>,
                  2: <Badge color="red.4">Rejected</Badge>,
                }[parseInt(item.approval_state, 10)] || <Badge>Draft</Badge>}
              </Group>

              {/* Comments */}

              <ActionIcon
                color="dimmed"
                onClick={() => {
                  selectedQuestion.current = item;
                  setOpenComment(true);
                }}
              >
                <Indicator
                  label={commentStats.has_new_comment}
                  showZero={!!commentStats.has_new_comment}
                  dot={!!commentStats.has_new_comment}
                  inline={!!commentStats.has_new_comment}
                  processing={commentStats.has_new_comment}
                  offset={7}
                  position="top-start"
                  color="red.7"
                >
                  <IconBrandHipchat stroke={1.5} />
                  {' '}

                </Indicator>
              </ActionIcon>

              <Menu shadow="md" width={200} withArrow>
                <Menu.Target>
                  <ActionIcon color="dimmed">
                    <IconDotsVertical size={16} stroke={1.5} />
                  </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Label>Irreversible actions</Menu.Label>
                  <Menu.Item icon={<IconArrowsLeftRight size={14} />}>Transfer question</Menu.Item>
                  <Menu.Item
                    color="red"
                    icon={<IconTrash size={14} />}
                    onClick={() => handleDeleting(item.id)}
                  >
                    Delete question
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Group>

            {media && (
              <div style={{ width: '80px', marginRight: '12px' }}>
                <Image
                  radius="md"
                  src={media}
                  alt={item.question}
                />
              </div>
            )}
          </Stack>
        </td>
      </tr>
    );
  });

  return (
    <Box>
      <Group position="apart" mb={20}>
        <Group>
          <Box p="10px">
            <Checkbox
              onChange={toggleAll}
              checked={selection.length}
              indeterminate={selection.length > 0 && selection.length !== data.length}
              transitionDuration={0}
            />
          </Box>

          <Select
            placeholder="Bulk Action"
            data={[
              { value: 'approve', label: 'Approve' },
              { value: 'reject', label: 'Reject' },
              { value: 'transfer', label: 'Transfer' },
              { value: 'delete', label: 'Delete' },
            ]}
          />
        </Group>

        <Search onSearch={onSearch} loading={loading} />
      </Group>

      <Box className={classes.wrap} mb="lg" style={{ position: 'relative' }}>
        <LoadingOverlay visible={loading} overlayBlur={2} radius="xl" />
        <Table>
          <tbody>{rows}</tbody>
        </Table>
      </Box>

      <CommentDrawer
        open={openComment}
        onClose={() => setOpenComment(false)}
        questionData={selectedQuestion.current}
        onCommentAdd={handleCommentAdded}
      />
    </Box>
  );
}

QuestionListTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    question: PropTypes.string.isRequired,
  })).isRequired,
  loading: PropTypes.bool,
  onSearch: PropTypes.func.isRequired,
  filters: PropTypes.shape().isRequired,
  onDelete: PropTypes.func.isRequired,
};

QuestionListTable.defaultProps = {
  loading: false,
};

export default QuestionListTable;
