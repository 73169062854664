import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Drawer, Paper, Textarea, Grid, Button, Loader, Stack,
  ScrollArea, createStyles, Text,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import * as yup from 'yup';
import { commentsData, updateCommentData } from '@hooks/useCommentData';
import useStore from '@zustand';
import Comment from './Comment';

const schema = yup.object().shape({
  comment: yup.string().required('Enter message'),
  author: yup.number(),
  question_id: yup.number().required(),
});

const useStyles = createStyles(() => ({
  root: {
    height: '100%',
  },
  viewport: {
    '> div': {
      height: '100%',
      display: 'flex !important',
    },
  },
}));

function CommentDrawer({
  open,
  onClose,
  questionData,
  onCommentAdd,
}) {
  const { classes } = useStyles();
  const viewport = useRef(null);
  const [question, setQuestion] = useState(questionData);
  const { data: comments, isLoading, error } = commentsData(question?.id || false); // eslint-disable-line
  const currentUser = useStore((s) => s.user);
  const { mutate, isLoading: sending } = updateCommentData();
  const { onSubmit, getInputProps, ...form } = useForm({
    validate: yupResolver(schema),
    initialValues: {
      comment: '',
      author: currentUser.ID,
      question_id: question?.id,
    },
  });

  const handleOnCLose = () => {
    onClose();
    // setQuestion({});
  };

  const handleSubmit = (data) => { // eslint-disable-line
    mutate(data, {
      onSuccess: (commentResponse) => {
        onCommentAdd({
          ...questionData,
          wordpress_data: JSON.stringify({
            comment_count: commentResponse.count,
            has_new_comment: true,
          }),
        });
        form.setFieldValue('comment', '');
        // console.log(viewport.current.offsetHeight);
        // viewport.current.scrollTo({ top: viewport.current.scrollHeight, behavior: 'smooth' });
        setTimeout(() => () => {
          viewport.current.scrollTo({ top: 2000, behavior: 'smooth' });
        }, 1000);
      },
    });
  };

  useEffect(() => {
    if (open) {
      setQuestion(questionData);

      form.setValues({
        question_id: questionData?.id,
      });
    }
  }, [open]);

  return (
    <Drawer
      opened={open}
      onClose={handleOnCLose}
      title="Comments"
      position="right"
      padding="xl"
      size="md"
    >
      <div style={{
        height: '100%', paddingBottom: '50px',
      }}
      >
        <Stack style={{ height: '100%', justifyContent: 'space-between' }}>
          <div style={{
            flex: '1', overflow: 'hidden', position: 'relative',
          }}
          >
            <ScrollArea classNames={classes} viewportRef={viewport}>
              <div style={{ display: 'flex' }}>
                {isLoading ? (
                  <div style={{
                    position: 'absolute', bottom: '40px', left: '50%', transform: 'translateX(-50%)',
                  }}
                  >
                    <Loader variant="bars" />
                  </div>
                ) : (
                  <Stack style={{ display: 'flex', width: '100%', marginTop: 'auto' }}>
                    {(error && !comments) && (
                      <div style={{
                        position: 'absolute', bottom: '40px', width: '100%', textAlign: 'center',
                      }}
                      >
                        <Text color="red.5">There was a problem getting comments.</Text>
                      </div>
                    )}

                    {comments && (
                      <>
                        {comments.length > 0
                          ? comments.map((comment) => (
                            <Comment
                              key={comment.comment_ID}
                              data={comment}
                            />
                          ))
                          : (
                            <div
                              style={{
                                position: 'absolute', bottom: '20px', width: '100%', textAlign: 'center',
                              }}
                            >
                              You have no comments
                            </div>
                          )}
                        <br />
                      </>
                    )}
                  </Stack>
                )}
              </div>
            </ScrollArea>
          </div>

          <Paper withBorder>
            <Grid align="flex-end">
              <Grid.Col span="auto">
                <Textarea
                  variant="unstyled"
                  placeholder="Send a comment"
                  px={15}
                  {...getInputProps('comment')}
                />
              </Grid.Col>
              <Grid.Col span="content" pb={15} pr={15}>
                <Button
                  onClick={onSubmit(handleSubmit)}
                  // onClick={() => viewport.current.scrollTo({
                    // top: viewport.current.scrollHeight, behavior: 'smooth' })}
                  loading={sending}
                >
                  Send

                </Button>
              </Grid.Col>
            </Grid>
          </Paper>
        </Stack>
      </div>
    </Drawer>
  );
}

CommentDrawer.defaultProps = {
  questionData: {},
};

CommentDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  questionData: PropTypes.shape({
    id: PropTypes.number,
  }),
  onCommentAdd: PropTypes.func.isRequired,
};

export default CommentDrawer;
