import {
  useQuery, useMutation, useQueryClient,
} from '@tanstack/react-query';
import {
  getCategories, getGameTypes, getAnswerType, uploadQuestions, updateQuestion,
  addQuestion,
  deleteQuestion,
  getQuestions,
} from '@services/trivia-stack';
import mutateToSelectOptions from '@utils/mutateToSelectOptions';

/**
 * Get Game Types
 * @returns useQueryResult
 */
export const gameTypesData = () => useQuery(['game-types'], getGameTypes, {
  staleTime: 'Infinity',
  select: (data) => mutateToSelectOptions(data),
});

/**
 * Game Type Raw
 * @returns useQueryResult without formatting data
 */
export const gameTypesDataRaw = () => useQuery(['game-types'], getGameTypes, {
  staleTime: 'Infinity',
  select: (data) => data,
});

/**
 * Game Categories
 * @returns useQueryResult without Uncategorized
 */
export const categoriesData = () => useQuery(['get-categories'], getCategories, {
  staleTime: 1000 * 30,
  select: (data) => mutateToSelectOptions(data.filter((d) => d.is_visible === 1)),
});

/**
 * Game Categories All
 * @returns useQueryResult
 */
export const categoriesAllData = () => useQuery(['get-categories'], getCategories, {
  staleTime: 1000 * 30,
  select: (data) => mutateToSelectOptions(data),
});

/**
 * Game Answer Types
 * @returns useQueryResult
 */
export const gameAnswerTypesData = () => useQuery(['get-answer-type'], getAnswerType, {
  staleTime: 'Infinity',
  select: (data) => mutateToSelectOptions(data),
});

/**
 * Import bulk questions
 * @returns useMutateResult
 */
export const importQuestionsData = () => {
  const queryClient = useQueryClient();

  return useMutation(uploadQuestions, {
    onSuccess: () => {
      queryClient.invalidateQueries('questions');
    },
  });
};

export const updateQuestionData = (filters) => {
  const queryClient = useQueryClient();
  const queryKey = ['questions', filters];

  return useMutation(updateQuestion, {
    onSuccess: (data) => {
      queryClient.setQueryData(queryKey, (oldQueryData) => {
        const newQueryData = oldQueryData.data.map(
          (item) => (
            parseInt(item.id, 10) === parseInt(data.id, 10) ? data : item
          ),
        );
        return {
          ...oldQueryData,
          data: newQueryData,
        };
      });
    },
  });
};

export const addQuestionData = () => {
  const queryClient = useQueryClient();

  return useMutation(addQuestion, {
    onSuccess: () => {
      queryClient.invalidateQueries('questions', { page: 1 });
    },
  });
};

export const deleteQuestionData = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteQuestion, {
    onSuccess: () => {
      queryClient.invalidateQueries('questions');
    },
  });
};

export const questionsData = (filters) => useQuery(
  ['questions', filters],
  () => getQuestions(filters),
  {
    keepPreviousData: true,
    staleTime: 1000 * 60,
    enabled: Object.keys(filters).length > 0,
  },
);
