import { useState, useEffect } from 'react';
import { useForm, yupResolver } from '@mantine/form';
import {
  Modal, ActionIcon, Grid, Select, Textarea, Stack,
  TextInput, Text, Group, Button,
} from '@mantine/core';
import { IconPlus } from '@tabler/icons';
import {
  gameTypesData, categoriesData, gameTypesDataRaw, gameAnswerTypesData,
  addQuestionData, updateQuestionData,
} from '@hooks/useTriviaStackData';
import useStore from '@zustand';
import OptionsInput from '@features/OptionsInput';
import AnswersInput from '@features/AnswersInput';
import ImageInput from '@components/ImageInput';
import schema from './schema.yup';

function AddQuestionModal() {
  const currentUser = useStore((s) => s.user);
  const hasEditQuestion = useStore((s) => s.editTriviaStackQuestion);
  const clearEdit = useStore((s) => s.setEditQuestion);
  const filters = useStore((s) => s.filters);

  const [action, setAction] = useState('add');
  const [open, setOpen] = useState(false);
  const [showCategorySelect, setShowCategory] = useState(false);

  const { data: gameTypes } = gameTypesData();
  const { data: categories } = categoriesData();
  const { data: gameTypeMeta } = gameTypesDataRaw();
  const { data: answerTypes } = gameAnswerTypesData();
  const { mutate: addQuestion, status: addingStatus } = addQuestionData();
  const { mutate: updateQuestion, status: updating } = updateQuestionData(filters);

  const {
    getInputProps, onSubmit, setFieldValue, errors, ...form
  } = useForm({
    validate: yupResolver(schema),
    initialValues: {
      game_type_id: null,
      category_id: null,
      answer_type_id: null,
      answers: [],
      options: [],
      chances: '',
      points: '',
      author: currentUser.email,
    },
  });
  const { onChange: handleOnGameChange, ...gameChangeInputProps } = getInputProps('game_type_id');

  const handleOnClose = () => {
    setOpen(false);
    setShowCategory(false);
    clearEdit({});
    form.reset();
  };

  const handleOptionsValues = (values) => {
    setFieldValue('options', values);
  };
  const handleAnswersValues = (values) => {
    setFieldValue('answers', values);
    setFieldValue('options', ['']);
  };

  const handleAnswerSelected = (answer) => {
    if (answer) {
      setFieldValue('answers', [answer]);
    } else {
      setFieldValue('answers', []);
    }
  };

  const handleImageFile = (fileObj) => {
    setFieldValue('media_file', fileObj);
  };

  const handleSubmit = (data) => {
    if (action === 'add') {
      addQuestion(data, {
        onSuccess: () => {
          handleOnClose();
        },
        onError: (error) => {
          if (error.response) {
            // form field errors
            if (error.response.status === 422) {
              const errorObj = error.response.data.errors;
              Object.entries(errorObj).map(([key, msg]) => {
                form.setFieldError(key, msg[0]);
                return {};
              });
            }
          }
        },
      });
    } else {
      updateQuestion(data, {
        onSuccess: () => {
          handleOnClose();
        },
      });
    }
  };

  useEffect(() => {
    if (hasEditQuestion && Object.keys(hasEditQuestion).length > 0) {
      setAction('update');
      form.setValues(hasEditQuestion);
      if (hasEditQuestion.category_id) {
        setShowCategory(true);
      }
      setOpen(true);
    }

    return () => { setAction('add'); };
  }, [hasEditQuestion]);

  return (
    <>
      <Modal
        opened={open}
        onClose={handleOnClose}
        title="Add question"
        size={640}
      >
        <Grid>
          <Grid.Col span="auto">
            <Grid>
              <Grid.Col span={6}>
                <Select
                  withAsterisk
                  label="Trivia stack game"
                  placeholder="select"
                  data={gameTypes}
                  {...gameChangeInputProps}
                  onChange={(value) => {
                    const meta = gameTypeMeta.filter((g) => g.id === value)[0];
                    handleOnGameChange(value);
                    setShowCategory(value !== 1 && value !== '');
                    const values = {
                      chances: meta.chances,
                      points: meta.points,
                    };
                    if (value === 1) {
                      values.category_id = 1;
                    } else {
                      values.category_id = null;
                    }
                    form.setValues(values);
                  }}
                />
              </Grid.Col>

              <Grid.Col span={6}>
                {showCategorySelect
                  ? (
                    <Select
                      withAsterisk
                      label="Category"
                      placeholder="select"
                      data={categories}
                      {...getInputProps('category_id')}
                    />
                  )
                  : null}
              </Grid.Col>
            </Grid>

            <Grid>
              <Grid.Col>
                <Textarea
                  withAsterisk
                  placeholder="type here.."
                  label="Question"
                  description="There is a limit in character."
                  autosize
                  minRows={2}
                  {...getInputProps('question')}
                />
              </Grid.Col>
            </Grid>
          </Grid.Col>

          <Grid.Col span="content">
            <Text weight={500} size="sm" style={{ lineHeight: 2 }}>
              Image
            </Text>
            <ImageInput onChange={handleImageFile} defaultImage={form.values.media} />
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col span={4}>
            <Stack spacing={8}>
              <Select
                withAsterisk
                label="Answer type"
                placeholder="select"
                data={answerTypes}
                {...getInputProps('answer_type_id')}
              />

              <TextInput
                label="Chances"
                placeholder="0"
                {...getInputProps('chances')}
              />

              <TextInput
                label="Points"
                placeholder="0"
                {...getInputProps('points')}
              />
            </Stack>
          </Grid.Col>

          <Grid.Col span="auto" pl={40}>
            {
              {
                1: (
                  <OptionsInput
                    disabled={form.values.answer_type_id === null}
                    onEnter={(values) => handleOptionsValues(values)}
                    error={errors?.options}
                    answerError={errors?.answers}
                    onAnswer={(answer) => handleAnswerSelected(answer)}
                    defaultValues={form.values.answer_type_id === 1
                      ? { answer: form.values.answers[0], options: form.values.options }
                      : {}}
                  />
                ),
                2: (
                  <AnswersInput
                    disabled={form.values.answer_type_id === null}
                    onEnter={(values) => handleAnswersValues(values)}
                    error={errors?.answers}
                    answerError={errors?.answers}
                    defaultValue={form.values.answer_type_id === 2 ? form.values.answers : []}
                  />
                ),
              }[form.values.answer_type_id || 1]
            }

          </Grid.Col>
        </Grid>

        <Group position="right" mt={42}>
          <Button variant="subtle" onClick={handleOnClose}>Cancel</Button>
          <div style={{ width: '200px' }}>
            <Button
              fullWidth
              size="lg"
              radius="xl"
              type="submit"
              loading={addingStatus === 'loading' || updating === 'loading'}
              disabled={addingStatus === 'loading' || updating === 'loading'}
              onClick={onSubmit(handleSubmit)}
            >
              Submit

            </Button>
          </div>
        </Group>
      </Modal>

      <ActionIcon color="dark.1" variant="outline" radius="xl" size="lg" onClick={() => setOpen(true)}>
        <IconPlus size={16} />
      </ActionIcon>
    </>
  );
}

export default AddQuestionModal;
