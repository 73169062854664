import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container, Title, TextInput, Paper, PasswordInput, Box, Button, Alert,
} from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons';
import { useForm, yupResolver } from '@mantine/form';
import * as yup from 'yup';
import { signInWithWP } from '@api/auth';
import useStore from '@zustand';
import { ReactComponent as BrainwaveLogo } from '@assets/svg/logo.svg';

const schema = yup.object().shape({
  email: yup.string().email('Invalid email').required(),
  password: yup.string().required(),
});

function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { user, storeToken, storeUser } = useStore();
  const isAuth = user && Object.keys(user).length > 0;
  const form = useForm({
    validate: yupResolver(schema),
    initialValues: {
      email: '',
      password: '',
    },
  });

  const handleSubmit = async (data) => {
    setLoading(true);
    setError(false);
    const res = await signInWithWP(data).catch(() => {
      setError(true);
      setLoading(false);
      return false;
    });

    if (res.token) {
      storeToken(res.token);
      storeUser(res.user);
      setLoading(false);
      navigate('/dashboard');
    }
  };

  useEffect(() => {
    if (isAuth) {
      navigate('/dashboard');
    }
    }, []); // eslint-disable-line

  return (
    <Container size={512} my={40}>
      <Box sx={() => ({
        backgroundColor: '#000', borderRadius: '100%', width: '64px', height: '64px', margin: '0 auto 20px',
      })}
      >
        <BrainwaveLogo style={{ fill: '#fff' }} />
      </Box>

      <Paper withBorder shadow="md" p={30} radius="md">
        <Title
          align="center"
          mb={30}
          sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900 })}
        >
          Brainwave IO

        </Title>

        <form onSubmit={form.onSubmit(handleSubmit)} autoComplete="off">
          <TextInput
            required
            label="Email"
            placeholder="you@provider.domain"
            {...form.getInputProps('email')}
          />

          <PasswordInput
            mt="md"
            required
            label="Password"
            placeholder="Your password"
            {...form.getInputProps('password')}
          />

          {error && (
            <Alert icon={<IconAlertCircle size={16} />} title="Invalid Credentials" color="red" mt="xl">
              Your email or password is incorrect.
            </Alert>
          )}

          <div style={{ width: '230px', margin: '0 auto' }}>
            <Button mt="xl" fullWidth type="submit" loading={loading}>Sign in</Button>
          </div>
        </form>
      </Paper>
    </Container>
  );
}

export default Login;
