const initialState = {
  user: {},
};

export default function authSlice(set) {
  return {
    ...initialState,

    storeToken: (token) => set((state) => ({
      ...state,
      token,
    })),
    storeUser: (payload) => set((state) => ({
      ...state,
      user: { ...payload },
    })),
    logout: () => set(initialState),
  };
}
